import { GroupFields } from "../MemberGroup/types";
import { InsuranceConfigurationSummaryFields } from "../Organization/types";

export const regionFields = `
  value
  label
`;

export const MemberInsuranceDetailFields = `
  _id
  organizationId
  primaryInsuranceCompany { ${InsuranceConfigurationSummaryFields} }
  secondaryInsuranceCompany { ${InsuranceConfigurationSummaryFields} }
`;

export const memberInsuranceCoverageFields = `
    _id
    availityExpirationDate
    availityUpdatedDate
    customerId
    cardIssueDate
    asOfDate
    toDate
    procedureCodes
    subscriberMedicaidId
    subscriberMemberId
    subscriberFirstName
    subscriberLastName
    subscriberMiddleName
    patientFirstName
    patientLastName
    patientMiddleName
    patientBirthDate
    patientGender
    subscriberRelationship
    payerId
    payerName

    requestedServiceType {
      code
      value
    }

    plans {
      _id
      status
      statusCode
      groupNumber
      groupName
      eligibilityStartDate
      eligibilityEndDate
      coverageStartDate
      coverageEndDate
      insuranceType

      additionalPayers {
        name
        type
        insuredId
      }
    }
`;

export const memberSearchInputField = `
  firstName
  lastName
  nickName
  dob
  pronouns
  race
  ethnicity
  spokenLanguages
  sex
  gender
  sexualOrientation
  medicareStatus
  medicaidStatus
  contactInfo {
    organizations
    address
    address2
    email
    country
    state
    zip
    city
    phone
  }
  timezone
  primaryCIN
  secondaryCIN

`;

const memberPhoneNumberFields = `
  _id
  digits
  type
  doNotCall
`;

const memberInputField = `
  firstName
  lastName
  nickName
  phonetic
  dob
  pronouns
  race
  ethnicity
  spokenLanguages
  sex
  gender
  sexualOrientation
  medicareStatus
  medicaidStatus
  diagnoses
  prescriptions
  pregnancyStatusEnum
  socialSecurityNumber
  peopleInHousehold
  childrenInHousehold
  employmentStatus
  employerName
  medicalRecordNumber
  maritalStatus
  highestLevelOfEducation
  maidenNameOrAlias
  familyMembers {
    memberId
    familyMemberName
    familyLinkType
  }
  
  contactInfo {
    organizations
    groups {
      joinedAt
      ${GroupFields}
    }
    address
    address2
    email
    country
    state
    zip
    city
    phoneNumbers {
      ${memberPhoneNumberFields}
    }
    primaryPhoneNumber {
      ${memberPhoneNumberFields}
    }
    doNotCall
  }
  timezone
  ehrID
  ehrIDType
  primaryCIN
  secondaryCIN
`;

export const memberFields = `
  _id
  ${memberInputField}
  lastEhrSync
  createdAt
  linkedContacts{
    _id
    name
    email
    phone
    relation
    type
    canBeCalled
    canBeTexted
  }
  assignedUserIds
  diagnosisCodes {
    code
    description
  }
  overriddenProcedureCodeLimits
  status
  reasonForDeactivation

  lastCoverageUpdate
  episodesOfCare {
    memberId
    _id
  }
  providerRecommendations {
    _id
    createdAt
    updatedAt
    status
    memberId
    batchId
    notes
    batch {
      _id
      createdAt
      updatedAt
      templateId
      signatureRequestId
      recommendingProvider {
        _id
        firstName
        lastName
        nationalProviderIdentifier
        title
        faxNumber
        companyName
      }
    }
  }
  insuranceCoverages {
    ${memberInsuranceCoverageFields}
  }
  insuranceDetails {
    ${MemberInsuranceDetailFields}
  }
`;

export const memberStatFields = `
  _id
  name
  memberId
  status
  groups
  assignedUsers
  numGoals
  numCompletedGoals
  numScheduledActivities
  numCompletedActivities
  completedGoalDate
  nextActivityDate
  prevActivityDate
`;

export const unassignedMemberFields = `
  _id
  firstName
  lastName
  status
  groups
`;

export const memberIdAndNameFields = `
  memberId
  firstName
  lastName
  nickName
  phoneNumber
`;

export const droppedMemberFields = `
  _id
  firstName
  lastName
  nickName
  phonetic
  status
  lastActivity
  groups
  users
`;

export const medicationFields = `
  _id
  memberId
  name
  dosage
  dosageUnit
  frequency
  frequencyInterval
  fillDate
  notes
  customFields {
    _id
    name
    value
  }
`;
