import {
  CarePathwayTemplate,
  CarePathwayTemplateStep,
  CarePathwayTemplateStepInput,
  CarePathway,
} from "src/graphql";
import { CarePathwayTemplateStepNodeData } from "src/pages/templates-carepathway/EditCarePathWayView";
import { Edge, Node } from "react-flow-renderer";
import { BUILDER_HEADER_HEIGHT } from "./CarePathwaysBuilderHeader";

export const createBaseCarePathwayTemplateObj = (
  organizationId: string,
  userId: string
): CarePathwayTemplate => {
  const firstStepId = generateMongoObjectID();
  return {
    _id: firstStepId,
    isDefault: false,
    updatedAt: "",
    createdAt: "",
    name: "",
    firstStepId: firstStepId,
    steps: [{ ...createBaseCarePathwayTemplateStep(), _id: firstStepId }],
    organizationId,
    userId,
    lockingReferences: [],
    lockedByUse: false,
    familyId: "",
    version: 0,
  };
};

export const createBaseCarePathwayTemplateStep =
  (): CarePathwayTemplateStep => ({
    _id: generateMongoObjectID(),
    actions: [],
    targetSteps: [],
    position: {
      x: 100,
      y: window.innerHeight / 2 - BUILDER_HEADER_HEIGHT,
    },
    isMultiOutput: false,
  });

export const generateFlowNodesFromCarePathwayTemplate = (
  firstStepId: string,
  stepsById: Record<string, CarePathwayTemplateStep>,
  deleteNode: (nodeId: string) => void,
  onConditionDelete: (conditionId: string) => void,
  onStepMultiOutputSwitched: (stepId: string) => void,
  isReadOnly: boolean,
  memberData?: CarePathway
): Node<CarePathwayTemplateStepNodeData>[] => {
  return Object.values(stepsById).map((step) =>
    generateCarePathwayTemplateStepNode(
      step,
      stepsById,
      deleteNode,
      onConditionDelete,
      onStepMultiOutputSwitched,
      isReadOnly,
      step._id === firstStepId,
      memberData
    )
  );
};

export const generateFlowEdgesFromCarePathwayTemplate = (
  stepsById: Record<string, CarePathwayTemplateStepInput>
): Edge[] => {
  const edges: Edge[] = [];

  Object.keys(stepsById).forEach((stepId) => {
    const step = stepsById[stepId];
    (step.targetSteps || []).forEach((targetStep) => {
      edges.push({
        id: step._id + targetStep.targetStepId,
        source: step._id ?? "",
        target: targetStep.targetStepId ?? "",
        sourceHandle: targetStep.sourceHandle,
        targetHandle: targetStep.targetHandle,
      });
    });
  });

  return edges;
};

export const generateCarePathwayTemplateStepNode = (
  step: CarePathwayTemplateStep,
  stepsById: Record<string, CarePathwayTemplateStep>,
  deleteNode: (nodeId: string) => void,
  onConditionDelete: (conditionId: string) => void,
  onStepMultiOutputSwitched: (stepId: string) => void,
  isReadOnly: boolean,
  isFirstStep?: boolean,
  memberData?: CarePathway
): Node<CarePathwayTemplateStepNodeData> => {
  return {
    id: step._id,
    position: step.position,
    data: {
      step,
      stepsById,
      isFirstStep,
      deleteNode,
      onConditionDelete,
      onStepMultiOutputSwitched,
      isReadOnly,
      memberData,
    },
    type: memberData ? "carePathwayStep" : "carePathwayTemplateStep",
  };
};

export const generateMongoObjectID = () => {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    "xxxxxxxxxxxxxxxx"
      .replace(/[x]/g, function () {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
};
