import React, { useMemo } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import styled from "styled-components";

// Auth Routes
import { AuthRoutes, RouteStyleConfig, SidebarSections } from "src/Router";

//  hooks
import { useAuthContext } from "src/hooks";
import { MASTHEAD_HEIGHT } from "./constants";
import { Alert, Stack } from "@mantine/core";
import dayjs from "dayjs";
import { IconBellRinging } from "@tabler/icons-react";
import { CustomerPortalButton } from "src/components/stripe-buttons/stripe-buttons";
import { Permission } from "src/graphql";

const StyledContainer = styled.div<{
  omitBottomPadding: boolean;
  bgColor?: string;
}>`
  height: calc(100vh - ${MASTHEAD_HEIGHT}px);
  margin-top: ${MASTHEAD_HEIGHT}px;
  overflow-y: auto;
  flex-grow: 1;
  padding: ${({ omitBottomPadding }) =>
    omitBottomPadding ? "20px 20px 20px 20px" : "20px 20px 120px 20px"};
  background-color: ${({ bgColor }) => bgColor || "white"};
`;

const sidebarRoutes = SidebarSections;

type ContentContainerProps = {
  omitBottomPadding: boolean;
};

export const ContentContainer = ({
  omitBottomPadding,
}: ContentContainerProps) => {
  const ctx = useAuthContext();
  const location = useLocation();
  const { selectedOrganization, hasExactPermissions, hasAnyPermission } = ctx;

  const shouldRedirectToAnalyticsPage = useMemo(() => {
    return (
      !location.pathname.includes("analytics") &&
      hasExactPermissions(Permission.AnalyticsAccess)
    );
  }, [hasExactPermissions, location.pathname]);

  const RpRedirectInfo = useMemo(() => {
    const isRp =
      !location.pathname.includes("review-members") &&
      !location.pathname.includes("settings/profile") &&
      hasAnyPermission(Permission.RecommendingProvider) &&
      !ctx.currentUser.isSuperAdmin;

    const isMemberDetailPage = location.pathname.match(/^\/members\/.+/);

    return {
      isRp,
      isMemberDetailPage,
    };
  }, [ctx.currentUser.isSuperAdmin, hasAnyPermission, location.pathname]);

  if (shouldRedirectToAnalyticsPage) {
    return <Redirect to="/analytics" />;
  }

  if (RpRedirectInfo.isRp && !RpRedirectInfo.isMemberDetailPage) {
    return <Redirect to="/review-members" />;
  }

  const showSubscriptionExpiredAlert =
    !selectedOrganization.subscriptionIsActive ||
    (selectedOrganization.subscriptionDueDate &&
      dayjs(selectedOrganization.subscriptionDueDate).isBefore(new Date()));

  const bgColor =
    RouteStyleConfig[location.pathname as keyof typeof RouteStyleConfig]
      ?.bgColor;

  return (
    <StyledContainer omitBottomPadding={omitBottomPadding} bgColor={bgColor}>
      <Alert
        title="Subscription Expired"
        color="red"
        variant="filled"
        mb="md"
        icon={<IconBellRinging />}
        hidden={
          !showSubscriptionExpiredAlert ||
          process.env.NODE_ENV === "development"
        }
      >
        {hasAnyPermission(Permission.OrganizationAccess) ? (
          <Stack align="flex-start">
            Your subscription has expired. To continue enjoying uninterrupted
            access to our services, please renew your subscription at your
            earliest convenience.
            <CustomerPortalButton
              organization={selectedOrganization}
              variant="default"
              type="submit"
            >
              Renew Subscription
            </CustomerPortalButton>
          </Stack>
        ) : (
          <Stack align="flex-start">
            Your subscription has expired. Please contact your organization's
            admin to renew the subscription.
          </Stack>
        )}
      </Alert>

      <Switch>
        <Route exact path="/">
          <Redirect to={"/dashboard"} />{" "}
        </Route>

        {Object.entries(AuthRoutes).map(([path, config]) => {
          const allowRoute =
            RpRedirectInfo.isRp && RpRedirectInfo.isMemberDetailPage;

          if (!allowRoute) {
            const routeDetails = sidebarRoutes
              .flatMap((section) => section.children)
              .find((v) => v.path === path);

            if (
              routeDetails &&
              !hasAnyPermission(...(routeDetails.allowedPermissions ?? []))
            ) {
              return null;
            }

            if (routeDetails?.omit && routeDetails.omit(ctx)) {
              return null;
            }
          }

          // dropping this here until FlowUI is merged in with better route config
          return (
            <Route exact={config.exact} path={path} key={path}>
              <React.Suspense fallback={null}>
                {<config.component />}
              </React.Suspense>
            </Route>
          );
        })}
      </Switch>
    </StyledContainer>
  );
};
