import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory, useLocation } from "react-router-dom";
import toast from "src/libs/toast";
import {
  Organization,
  User,
  useQueryUserState,
  OrganizationStatus,
  Permission,
  useQueryOrganization,
  OrganizationForUser,
} from "src/graphql";
import { SelectOption } from "src/types";
import { useURLQueryParams } from "src/hooks/useURLQueryParams";
import {
  AuthFlowContainer,
  AuthFlowState,
} from "src/components/auth/AuthFlowContainer";
import { useApolloClient } from "@apollo/client";
import log from "loglevel";
import { isEqual } from "lodash";

const NAMESPACE = process.env.REACT_APP_AUTH0_DOMAIN;

const authFlowRoutes = [
  "/login",
  "/reset",
  "/signup",
  "/forgot-password",
  "/onboarding-survey",
  "/referral-status",
  "/plans",
];

type Auth0User = User & {
  sub: string;
  auth0Metadata?: Record<string, unknown>;
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export type AuthContextType = {
  currentUser: Auth0User;
  selectedOrganizationId: string;
  selectedOrganization: Organization;
  organizations: OrganizationForUser[];
  organizationOptions: SelectOption<string>[];
  groupOptions: SelectOption<string>[];
  memberPageReadOnly: boolean;
  signOut: () => void;
  setSelectedOrganizationId: (nextId: string) => boolean;
  hasAnyPermission: (...permissions: Permission[]) => boolean;
  hasExactPermissions: (...permissions: Permission[]) => boolean;
};

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType,
);

type AuthProviderProps = {
  children: React.ReactNode;
};

export const allPermissions = [
  Permission.OrganizationAccess,

  Permission.BuilderToolsReadAccess,
  Permission.BuilderToolsWriteAccess,

  Permission.ClaimsSubmission,
  Permission.ClaimGeneration,

  Permission.MemberWriteAccess,
  Permission.MemberReadAccess,

  Permission.RecommendationWriteAccess,
  Permission.RecommendationReadAccess,

  Permission.DataImportAndExport,

  Permission.ExternalResourceWriteAccess,

  Permission.AnalyticsAccess,

  Permission.DataManagement,

  Permission.UserManagement,

  Permission.RecommendingProvider,
];

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { user, isLoading, logout, getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const location = useLocation();
  const client = useApolloClient();
  const urlQueryParams = useURLQueryParams();
  const [selectedOrganizationId, setSelectedOrganizationId] = useState("");
  const [memberPageReadOnly, setMemberPageReadOnly] = useState(false);
  const [authFlowState, setAuthFlowState] = useState<AuthFlowState>(
    AuthFlowState.Loading,
  );

  // Retrieve the UID from Auth0, using the custom namespace where Firebase UID was stored.
  // This UID matches our database `user_id`, ensuring consistent user identification after
  // migrating from Firebase to Auth0.
  // See custom action in Auth0 for more details.
  const uid = user ? user[`${NAMESPACE}/uid`] : null;

  // If `uid` is null, this is a new user (not imported from Firebase), so we
  // use the `sub` field from Auth0.
  const { data: pearUserStateResponse, loading: userStateLoading } =
    useQueryUserState(uid || user?.sub);

  // Resolve the pathname without triggering unnecessary renders
  const rootPath = useMemo(() => {
    if (location.pathname) {
      return location.pathname.split("/").slice(0, 2).join("/");
    }
    return "/";
  }, [location.pathname]);

  // Set default values for `pearUser`, `organizations`, and `userPerms`
  const [pearUser, organizations] = useMemo(() => {
    const pearUser = pearUserStateResponse?.user.data || ({} as User);
    const mergedUser = {
      ...pearUser,
      ...{
        ...user,
        sub: user?.sub ?? "",
        auth0Metadata: user?.user_metadata || {},
      },
      name: pearUser.name,
    };

    return [
      mergedUser,
      pearUserStateResponse?.organizationsForUser?.data || [],
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pearUserStateResponse, user]);

  const hasAnyPermission = useCallback(
    (...permissions: Permission[]) => {
      if (pearUser?.isSuperAdmin) {
        return true;
      }

      const userPermissions =
        pearUser?.userOrgRoles
          ?.filter((r) => r.organizationId === selectedOrganizationId)
          .flatMap((v) => v.permissions ?? []) ?? [];

      const result = userPermissions.some((p) => permissions.includes(p));
      return result;
    },
    [pearUser?.isSuperAdmin, pearUser?.userOrgRoles, selectedOrganizationId],
  );

  const hasExactPermissions = useCallback(
    (...permissions: Permission[]) => {
      const userPermissions =
        pearUser?.userOrgRoles
          ?.filter((r) => r.organizationId === selectedOrganizationId)
          .flatMap((v) => v.permissions ?? []) ?? [];

      const result = isEqual(userPermissions.sort(), permissions.sort());
      return result;
    },
    [pearUser?.userOrgRoles, selectedOrganizationId],
  );

  useEffect(() => {
    if (!hasAnyPermission(Permission.MemberWriteAccess)) {
      setMemberPageReadOnly(true);
    } else if (
      memberPageReadOnly &&
      hasAnyPermission(Permission.MemberWriteAccess)
    ) {
      setMemberPageReadOnly(false);
    }
  }, [hasAnyPermission, memberPageReadOnly]);

  // Memoize active organizations to avoid recomputing unnecessarily
  const activeOrganizations = useMemo(
    () =>
      organizations.filter((org) => org.status === OrganizationStatus.Active),
    [organizations],
  );

  const organizationOptions = useMemo(
    () =>
      activeOrganizations
        .map((org) => ({
          label: org.title,
          value: org._id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [activeOrganizations],
  );

  // Authentication handler
  const handleUserAuthenticated = useCallback(() => {
    setAuthFlowState(AuthFlowState.Authenticated);
    if (authFlowRoutes.includes(rootPath)) {
      const redirect = urlQueryParams.get("redirect") || "/";
      history.push(redirect === "/settings" ? "/settings/profile" : redirect);
    }
  }, [history, rootPath, urlQueryParams]);

  const handleSignOut = () => {
    setSelectedOrganizationId("");
    setAuthFlowState(AuthFlowState.LoggedOut);
    client.clearStore();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  // Handle organization selection
  const handleSelectOrg = useCallback(
    (nextId: string) => {
      const org = activeOrganizations.find((org) => org._id === nextId);
      if (!org) {
        toast.error("Could not select requested organization");
        return false;
      }

      localStorage.setItem("lastSelectedOrgName", org.title);
      setSelectedOrganizationId(nextId);
      client.resetStore().catch((error) => {
        log.error("Error resetting Apollo store", error);
      });
      return true;
    },
    // [activeOrganizations]
    [activeOrganizations, client],
  );

  // Handle org select on sign in
  // NOTE JL: temp? quick fix to clean up noisy console errors, need convo with alison
  //          for context on above `client.resetStore()` call in `handleSelectOrg
  const handleSetInitialOrg = useCallback(() => {
    const lastSelectedOrgName = localStorage.getItem("lastSelectedOrgName");
    const initialOrg =
      activeOrganizations.find((org) => org.title === lastSelectedOrgName) ??
      activeOrganizations[0];

    if (!initialOrg) {
      toast.error("Could not select requested organization");
      return false;
    }

    localStorage.setItem("lastSelectedOrgName", initialOrg.title);
    setSelectedOrganizationId(initialOrg._id);
    return true;
  }, [activeOrganizations]);

  const { data: selectedOrganizationData } = useQueryOrganization(
    selectedOrganizationId
  );

  const selectedOrganization = selectedOrganizationData?.organization?.data;

  const groupOptions = useMemo(
    () =>
      selectedOrganization?.groups
        ?.map((group) => ({
          label: group.title,
          value: group._id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
    [selectedOrganization],
  );

  /**
   * Auth flow state MGMT
   */

  // when initial auth state is loaded
  useEffect(() => {
    if (isLoading || userStateLoading) return;

    const isOnboardingSurveyRoute =
      location.pathname.startsWith("/onboarding-survey");

    if (!user && authFlowState !== AuthFlowState.Authenticated) {
      setAuthFlowState(AuthFlowState.LoggedOut);
      if (!authFlowRoutes.includes(rootPath)) {
        history.push(`/login?redirect=${rootPath}`);
      }
    } else if (user && authFlowState === AuthFlowState.Loading) {
      if (isOnboardingSurveyRoute) {
        // return early if logged in and onboarding survey route
        return;
      }
      if (pearUser?._id) {
        if (rootPath === "/plans") {
          // if user is on plans page, don't redirect
          return;
        }
        handleUserAuthenticated();
      } else {
        setAuthFlowState(AuthFlowState.UserDocNotFound);
      }
    }
  }, [
    isLoading,
    authFlowState,
    pearUser,
    user,
    pearUserStateResponse,
    history,
    rootPath,
    handleUserAuthenticated,
    userStateLoading,
    location.pathname,
  ]);

  useEffect(() => {
    if (
      user &&
      pearUser &&
      activeOrganizations.length > 0 &&
      !selectedOrganizationId
    ) {
      handleSetInitialOrg();
    }
  }, [
    user,
    authFlowState,
    pearUser,
    pearUserStateResponse,
    selectedOrganizationId,
    activeOrganizations,
    handleSetInitialOrg,
  ]);

  useEffect(() => {
    const refreshToken = async () => {
      try {
        await getAccessTokenSilently();
      } catch (err) {
        log.error("Token refresh failed:", err);
        if (!authFlowRoutes.includes(rootPath) && rootPath !== "/plans") {
          history.push(`/login?redirect=${rootPath}`);
        }
      }
    };

    if (authFlowState === AuthFlowState.Authenticated) {
      refreshToken();
    }
  }, [authFlowState, getAccessTokenSilently, rootPath, history]);

  // Application readiness check
  const applicationReady =
    authFlowState === AuthFlowState.Authenticated &&
    !!user &&
    !!pearUserStateResponse && // Ensure data is fully loaded
    !!pearUser &&
    !!selectedOrganizationId &&
    !!selectedOrganization;

  return applicationReady ? (
    <AuthContext.Provider
      value={{
        currentUser: pearUser,
        selectedOrganizationId,
        selectedOrganization,
        organizations,
        organizationOptions,
        groupOptions,
        signOut: handleSignOut,
        setSelectedOrganizationId: handleSelectOrg,
        memberPageReadOnly,
        hasAnyPermission,
        hasExactPermissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  ) : (
    <AuthFlowContainer
      onUserAuthenticated={handleUserAuthenticated}
      onSignOut={handleSignOut}
      authFlowState={authFlowState}
    />
  );
};
