import dayjs from "dayjs";
import { useMemo } from "react";
import {
  GetFlowBuilderDataResponse,
  FlowTemplate,
  ActionType,
  GoalTemplate,
  ActivityTemplate,
  DataIdWithAnswerType,
  DataIdWithAnswerTypeResponse,
  useQueryFlowBuilderData,
  FlowType,
  ExternalResource,
  CarePathwayTemplate,
  ConsentTemplate,
  Group,
  FlowTemplateWithAccess,
  FlowTemplateFamilyWithAccess,
} from "src/graphql";
import { useQueryDataIdsWithAnswerTypesByOrganizationId } from "src/graphql/DataPoint";
import { useAuthContext } from "src/hooks";
import { BASE_CREATE_TEMPLATE_ID } from "src/pages/templates";
import { arrayToKeyedObj } from "src/utils";
import { makeDefaultStagePositions } from "./template-editor";
import { BUILDER_HEADER_HEIGHT } from "./template-editor/FlowTemplateBuilderHeader";

export type FlowBuilderData = {
  flowTemplatesById: Record<string, FlowTemplateWithAccess>;
  flowTemplateFamiliesById: Record<string, FlowTemplateFamilyWithAccess>;
  dataIdInformaionById: Record<string, DataIdWithAnswerType>;
  actionOptionsByTypeById: {
    [ActionType.CreateGoalData]: Record<string, GoalTemplate>;
    [ActionType.UpdateGoalData]: Record<string, GoalTemplate>;
    [ActionType.ScheduleActivityData]: Record<string, ActivityTemplate>;
    [ActionType.UpdateMemberData]: Record<string, DataIdWithAnswerType>;
    [ActionType.CreateReferral]: Record<string, ExternalResource>;
    [ActionType.StartCarePathway]: Record<string, CarePathwayTemplate>;
    [ActionType.UpdateCarePathwayStatus]: Record<string, CarePathwayTemplate>;
    [ActionType.CreateConsent]: Record<string, ConsentTemplate>;
    [ActionType.AddMemberToGroup]: Record<string, Group>;
    [ActionType.RemoveMemberFromGroup]: Record<string, Group>;
  };
};

export const useFlowBuilderData = () => {
  const { selectedOrganizationId, selectedOrganization } = useAuthContext();
  const { data: builderDataResponse, loading: builderDataLoading } =
    useQueryFlowBuilderData(selectedOrganizationId);

  const { data: dataIdsByOrganizationResponse, loading: dataIdDataLoading } =
    useQueryDataIdsWithAnswerTypesByOrganizationId(selectedOrganizationId);

  const parsedData = useParsedFlowBuilderData(
    builderDataResponse,
    dataIdsByOrganizationResponse?.dataIdsWithAnswerTypesByOrganizationId,
    selectedOrganization.groups ?? []
  );

  return {
    isLoading: builderDataLoading || dataIdDataLoading,
    parsedData,
  };
};

export const useParsedFlowBuilderData = (
  builderDataResponse?: GetFlowBuilderDataResponse,
  dataIdResponse?: DataIdWithAnswerTypeResponse,
  groups?: Group[]
): FlowBuilderData | null => {
  return useMemo(() => {
    if (!builderDataResponse || !dataIdResponse) return null;

    const flowTemplateFamilies =
      builderDataResponse.flowTemplateFamilies.data ?? [];
    const goalTemplates =
      builderDataResponse.goalTemplates.data?.map((x) => x.goalTemplate) ?? [];
    const carePathwayTemplates =
      builderDataResponse.carePathwayTemplateFamiliesByOrganizationId.data ??
      [];
    const activityTemplates =
      builderDataResponse.activityTemplates.data?.map(
        (t) => t.activityTemplate
      ) ?? [];
    const dataIds = dataIdResponse?.data ?? [];
    const consentTemplates =
      builderDataResponse.consentTemplatesByOrganizationId.data ?? [];
    const externalResources = (
      builderDataResponse.externalResources.data ?? []
    ).filter((v) => v.isActive);

    const flowTemplatesById = flowTemplateFamilies.reduce(
      (byId, family) => {
        byId[family.flowTemplateFamily.currentVersion._id] = {
          flowTemplate: family.flowTemplateFamily.currentVersion,
          accessType: family.accessType,
        };
        family.flowTemplateFamily.priorVersions.forEach(
          (template) =>
            (byId[template._id] = {
              flowTemplate: template,
              accessType: family.accessType,
            })
        );
        return byId;
      },
      {} as Record<string, FlowTemplateWithAccess>
    );

    const flowTemplateFamiliesById = flowTemplateFamilies.reduce(
      (byId, family) => {
        byId[family.flowTemplateFamily.currentVersion.familyId] = family;
        return byId;
      },
      {} as Record<string, FlowTemplateFamilyWithAccess>
    );
    const goalTemplatesById = arrayToKeyedObj(goalTemplates, "_id");
    const carePathwayTemplatesByFamilyId = arrayToKeyedObj(
      carePathwayTemplates.map((v) => v.currentVersion),
      "familyId"
    );
    const activityTemplatesById = arrayToKeyedObj(activityTemplates, "_id");
    const dataIdInformaionById: Record<string, DataIdWithAnswerType> =
      arrayToKeyedObj(dataIds, "dataId");
    const externalResourcesById = arrayToKeyedObj(externalResources, "_id");
    const consentTemplatesById = arrayToKeyedObj(consentTemplates, "_id");
    const groupsById = arrayToKeyedObj(groups ?? [], "_id");

    const actionOptionsByTypeById = {
      [ActionType.CreateGoalData]: goalTemplatesById,
      [ActionType.ScheduleActivityData]: activityTemplatesById,
      [ActionType.UpdateGoalData]: goalTemplatesById,
      [ActionType.UpdateMemberData]: dataIdInformaionById,
      [ActionType.CreateReferral]: externalResourcesById,
      [ActionType.StartCarePathway]: carePathwayTemplatesByFamilyId,
      [ActionType.UpdateCarePathwayStatus]: carePathwayTemplatesByFamilyId,
      [ActionType.CreateConsent]: consentTemplatesById,
      [ActionType.AddMemberToGroup]: groupsById,
      [ActionType.RemoveMemberFromGroup]: groupsById,
    };

    return {
      flowTemplatesById,
      flowTemplateFamiliesById,
      dataIdInformaionById,
      actionOptionsByTypeById,
    };
  }, [builderDataResponse, dataIdResponse, groups]);
};

const defaultStartNodeX = 100;

export const createBaseFlowTemplate = (
  organizationId: string,
  type: FlowType
): FlowTemplate => ({
  _id: BASE_CREATE_TEMPLATE_ID,
  chapters: [],
  createdAt: dayjs().toISOString(),
  updatedAt: dayjs().toISOString(),
  organizationId: organizationId,
  startNodePosition: {
    x: defaultStartNodeX,
    y: window.innerHeight / 2 - BUILDER_HEADER_HEIGHT,
  },
  stagePositions: makeDefaultStagePositions(defaultStartNodeX),
  entryStepId: "",
  steps: [],
  title: "",
  lockingReferences: [],
  lockedByUse: false,
  flowType: type,
  familyId: "THIS_IS_A_DUMMY_ID_IGNORE_THIS", // set by backend on creation
  version: 1,
  isActive: true,
});
