import { EnrollmentStatus, PhoneNumberType, Sex } from "src/graphql";
import { getEnumStringValues, ySelectOptionSchema } from "src/utils";
import * as Yup from "yup";

export const phoneRegex =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const MemberPhoneNumberSchema = Yup.object().shape({
  digits: Yup.string()
    .matches(phoneRegex, "Invalid Phone number")
    .required("Required"),
  type: ySelectOptionSchema(
    Yup.string().oneOf(getEnumStringValues(PhoneNumberType))
  ).required(),
  doNotCall: Yup.boolean().required(),
  isPrimary: Yup.boolean().required(),
});

export const MembersSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .min(2, "Name must be longer than 2 characters")
    .max(50, "Name must be shorter than 50 characters")
    .required("Required"),
  lastName: Yup.string()
    .trim()
    .min(2, "Name must be longer than 2 characters")
    .max(50, "Name must be shorter than 50 characters")
    .required("Required"),
  nickName: Yup.string()
    .trim()
    .min(2, "Name must be longer than 2 characters")
    .max(50, "Name must be shorter than 50 characters")
    .nullable(true),
  medicareStatus: ySelectOptionSchema(
    Yup.string().oneOf(getEnumStringValues(EnrollmentStatus))
  ).default(undefined),
  medicaidStatus: ySelectOptionSchema(
    Yup.string().oneOf(getEnumStringValues(EnrollmentStatus))
  ).default(undefined),
  dob: Yup.date().nullable(true),
  socialSecurityNumber: Yup.string()
    .min(9, "SSN must be at least 9 characters")
    .nullable(true),
  sex: ySelectOptionSchema(
    Yup.string().oneOf(getEnumStringValues(Sex))
  ).default(undefined),
  primaryCIN: Yup.string().nullable(true),
  primaryInsuranceCompany: ySelectOptionSchema(Yup.string().required())
    .strict(true)
    .nullable(true),
  secondaryCIN: Yup.string().nullable(true),
  secondaryInsuranceCompany: ySelectOptionSchema(Yup.string().required())
    .strict(true)
    .nullable(true),
  contactInfo: Yup.object().shape({
    email: Yup.string().trim().email("Invalid email").nullable(true),
    address: Yup.string().trim().nullable(true),
    address2: Yup.string().trim().nullable(true),
    country: Yup.mixed(),
    zip: Yup.string()
      .trim()
      .max(30, "Max length of character should be 30")
      .nullable(true),
    phoneNumbers: Yup.array(MemberPhoneNumberSchema)
      .min(1, "Must pass at least 1 valid Phone Number")
      .required(),
  }),
  ehrID: Yup.string().nullable(true),
  ehrIDType: ySelectOptionSchema(Yup.string()).default(undefined),
  peopleInHousehold: Yup.number()
    .nullable(true)
    .min(0, "Number of People in Household must be at least 0"),
  childrenInHousehold: Yup.number()
    .nullable(true)
    .min(0, "Number of Children in Household must be at least 0"),
});
