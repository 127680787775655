import { Button, Group, Stack } from "@mantine/core";
import toast from "src/libs/toast";
import { useMediaQuery } from "@mantine/hooks";
import { Formik, FormikHelpers, Form } from "formik";
import { useCallback, useMemo, useState } from "react";
import {
  sexSelectOptions,
  MemberFormValues,
  unwrapFormValues,
  pronounsSelectOptions,
  ehrIDTypeOptions,
  enrollmentStatusSelectOptions,
  MemberValues,
  genderSelectOptions,
  pregnancyStatusSelectOptions,
  ethnicitySelectOptions,
  sexualOrientationSelectOptions,
  useEHRIntegrations,
  useInsuranceCompanyOptions,
  useMemberFormInitialValues,
  maritalStatusSelectOptions,
  employmentStatusSelectOptions,
  highestLevelOfEducationSelectOptions,
} from "./MemberFormFormik";
import { BasicMemberInfoFormSection } from "./onboarding-form-sections/BasicMemberInfoFormSection";
import { MemberProfileInformationSection } from "./onboarding-form-sections/MemberProfileInformationSection";
import { InsuranceInformationFormSection } from "./onboarding-form-sections/InsuranceInformationFormSection";
import { MemberContactDetailsSection } from "./onboarding-form-sections/MemberContactDetailsSection";
import { EHRDetailsSection } from "./onboarding-form-sections/EHRDetailsSection";
//utils

import { MembersSchema } from "src/validation";

// custom component & constants
import {
  languageSelectOptions,
  raceSelectOptions,
  timezoneSelectOptions,
} from "src/constants/member";
import { CustomStepper } from "src/components/custom-stepper";

// Types
import { Member, MemberProfileSettings, Organization } from "src/graphql";

type MemberFormProps = {
  member?: Member;
  onSubmit: (values: MemberValues, reset: () => void) => Promise<void>;
  organization: Organization;
  loading?: boolean;
  submitLabel?: string;
  renderFlowSurvey: (props: { onComplete: () => void }) => JSX.Element;
};

export const OnboardingMemberFormFormik = ({
  member,
  onSubmit,
  organization,
  loading = false,
  submitLabel = "Submit",
  renderFlowSurvey,
}: MemberFormProps) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const memberProfileSettings: MemberProfileSettings = useMemo(
    () => organization?.onboardingMemberProfileSettings ?? {},
    [organization],
  );

  const insuranceCompanyOptions = useInsuranceCompanyOptions(organization);
  const ehrIntegrationsEnabled = useEHRIntegrations(organization);
  const initValues = useMemberFormInitialValues(member, organization);

  const steps = useMemo(
    () =>
      [
        {
          id: "basicInfo",
          label: "Basic Info",
          Component: () => (
            <BasicMemberInfoFormSection
              memberProfileSettings={memberProfileSettings}
            />
          ),
        },
        {
          id: "profile",
          label: "Profile",
          visible: !(
            memberProfileSettings.memberPreferredNameHidden &&
            memberProfileSettings.memberDOBHidden &&
            memberProfileSettings.memberRaceHidden &&
            memberProfileSettings.memberEthnicityHidden &&
            memberProfileSettings.memberSexHidden &&
            memberProfileSettings.memberGenderHidden &&
            memberProfileSettings.memberPronounsHidden &&
            memberProfileSettings.memberSexualOrientationHidden &&
            memberProfileSettings.memberPregnancyHidden &&
            memberProfileSettings.memberLanguagesHidden
          ),
          Component: () => (
            <MemberProfileInformationSection
              memberProfileSettings={memberProfileSettings}
              raceSelectOptions={raceSelectOptions}
              ethnicitySelectOptions={ethnicitySelectOptions}
              sexSelectOptions={sexSelectOptions}
              genderSelectOptions={genderSelectOptions}
              pronounsSelectOptions={pronounsSelectOptions}
              sexualOrientationSelectOptions={sexualOrientationSelectOptions}
              pregnancyStatusSelectOptions={pregnancyStatusSelectOptions}
              languageSelectOptions={languageSelectOptions}
              employmentStatusSelectOptions={employmentStatusSelectOptions}
              maritalStatusSelectOptions={maritalStatusSelectOptions}
              highestLevelOfEducationSelectOptions={
                highestLevelOfEducationSelectOptions
              }
              shouldShowTitle
            />
          ),
        },
        {
          id: "insurance",
          visible:
            !memberProfileSettings.memberPrimaryInsuranceHidden ||
            !memberProfileSettings.memberSecondaryInsuranceHidden,
          label: "Insurance",
          Component: () => (
            <InsuranceInformationFormSection
              memberProfileSettings={memberProfileSettings}
              insuranceCompanyOptions={insuranceCompanyOptions}
              enrollmentStatusSelectOptions={enrollmentStatusSelectOptions}
            />
          ),
        },
        {
          id: "contactDetails",
          visible: !memberProfileSettings.memberAddressHidden,
          label: "Contact Details",
          Component: () => (
            <MemberContactDetailsSection
              timezoneSelectOptions={timezoneSelectOptions}
            />
          ),
        },
        {
          id: "ehr",
          visible: ehrIntegrationsEnabled,
          label: "EHR Integrations",
          Component: () => (
            <EHRDetailsSection ehrIDTypeOptions={ehrIDTypeOptions} />
          ),
        },
        {
          id: "flowSurvey",
          Component: () =>
            // NOTE: Do not increment instead, it will render infinitely
            // Active steps changes -> Re-render -> Increment active step -> Re-render
            // The flow logic uses useEffect to handle the completion of the survey
            renderFlowSurvey({ onComplete: () => setActiveStep(Infinity) }),
          label: "Flow Survey",
        },
      ].filter(
        (step) =>
          (typeof step.Component === "function" && step.visible) ||
          step.id === "basicInfo" ||
          step.id === "flowSurvey",
      ),

    [
      memberProfileSettings,
      ehrIntegrationsEnabled,
      renderFlowSurvey,
      insuranceCompanyOptions,
    ],
  );

  const totalSteps = steps.length;
  const [activeStep, setActiveStep] = useState<number>(0);

  const onFormSubmit = useCallback(
    async (
      values: MemberFormValues,
      { resetForm, setSubmitting }: FormikHelpers<MemberFormValues>
    ) => {
      setSubmitting(true);
      const unwrapped = unwrapFormValues(values);
      await onSubmit(unwrapped, resetForm);
      setSubmitting(false);
      setActiveStep((current) => current + 1); // Proceed to render the survey
    },
    [onSubmit]
  );

  const isLastStep = useMemo(
    () => activeStep === totalSteps - 2,
    [activeStep, totalSteps],
  );

  useCallback(
    (values: MemberFormValues, actions: FormikHelpers<MemberFormValues>) => {
      if (isLastStep) {
        onFormSubmit(values, actions);
      } else {
        setActiveStep((current) => current + 1); // Move to next step
      }
    },
    [isLastStep, onFormSubmit],
  );

  const handleBack = useCallback(() => {
    setActiveStep((current) => current - 1); // Move to previous step
  }, []);

  const CurrentStep = steps[Math.min(activeStep, totalSteps - 1)];

  return (
    <Formik
      initialValues={initValues}
      validationSchema={MembersSchema}
      validateOnChange={true}
      enableReinitialize={true}
      onSubmit={onFormSubmit}
    >
      {({ handleSubmit, validateForm, setTouched, touched }) => {
        const handleNext = async (): Promise<void> => {
          const formErrors = await validateForm();

          if (Object.keys(formErrors).length > 0) {
            setTouched({
              ...touched,
              ...Object.keys(formErrors).reduce(
                (acc, key) => ({ ...acc, [key]: true }),
                {},
              ),
            });
            toast.error(
              "Please fill out all required fields before proceeding to the next step.",
            );
            return;
          }

          if (activeStep === totalSteps - 2) {
            handleSubmit(); // Handle form submission before the final survey step
          } else if (activeStep < totalSteps - 1) {
            setActiveStep((current) => current + 1); // Proceed to the next step
          }
        };
        return (
          <div
            style={{
              width: "100%",
              margin: "0 auto",
              position: "relative",
              height: "100%",
              overflowY: "auto",
              padding: isMobile ? "0 10px" : "0 20px",
            }}
          >
            <Stack align="center" spacing="xl">
              <CustomStepper
                steps={steps.map((step) => step.label)}
                activeStep={activeStep}
              />

              <Form
                onSubmit={handleSubmit}
                style={{ width: isMobile ? "90vw" : "80vw" }}
              >
                {CurrentStep && <CurrentStep.Component />}

                {/* We want to show the back button in case the member is not created yet */}
                {activeStep !== Infinity && CurrentStep.id !== "flowSurvey" && (
                  <Group
                    mx="lg"
                    position="center"
                    pos="fixed"
                    bottom={100}
                    left={0}
                    right={0}
                  >
                    <Button
                      variant="default"
                      onClick={handleBack}
                      disabled={activeStep === 0 || member !== undefined}
                      style={isMobile ? { flex: 1, marginRight: "5px" } : {}}
                    >
                      Back
                    </Button>
                    <Button
                      loading={loading}
                      onClick={() => handleNext()}
                      style={isMobile ? { flex: 1 } : {}}
                      disabled={activeStep === totalSteps - 1}
                    >
                      {isLastStep ? submitLabel : "Next"}
                    </Button>
                  </Group>
                )}
              </Form>
            </Stack>

            {!isMobile && (
              <div
                style={{
                  position: "absolute",
                  bottom: "1px",
                  right: "1px",
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                  padding: "2px 5px",
                  borderRadius: "5px",
                  fontSize: "10px",
                  color: "gray",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                }}
              >
                {organization?.title || "Organization"}
              </div>
            )}
          </div>
        );
      }}
    </Formik>
  );
};
